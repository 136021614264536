@charset "UTF-8";

/* base
------------------------------------------------------------- */
form.cmxform label.error, label.error,
form.cmxform em.error, em.error {
  color: red;
  display: block;
}


input[type=text], input[type=email], input[type=tel], textarea {
  &.error-highlight {
    background-color: #ffdce2;
  }
}